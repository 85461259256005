import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  routes: [
    {
      path: "/",
      // name: "HomeView",
      // component: () => import("@/views/HomeView.vue"),
      redirect: "/link",
      meta: { requireLogin: true },
    },

    //account
    {
      path: "/account/login",
      name: "LoginView",
      component: () => import("@/views/account/LoginView.vue"),
      beforeEnter: (to, from, next) => {
        if (store.getters["auth/authToken"]) {
          //이미 로그인된 상태에서 로그인 페이지로 가는 경우
          return next({ name: "CreateLink" });
        }
        next();
      },
    },
    {
      path: "/account/register",
      name: "RegisterView",
      component: () => import("@/views/account/RegisterView.vue"),
      beforeEnter: (to, from, next) => {
        if (store.getters["auth/authToken"]) {
          //이미 로그인된 상태에서 회원가입 페이지로 가는 경우
          return next({ name: "CreateLink" });
        }
        next();
      },
    },
    {
      path: "/account/password",
      name: "PasswordView",
      component: () => import("@/views/account/PasswordView.vue"),
      beforeEnter: (to, from, next) => {
        if (store.getters["auth/authToken"]) {
          //이미 로그인된 상태에서 비밀번호 찾기 페이지로 가는 경우
          return next({ name: "CreateLink" });
        }
        next();
      },
    },

    //menu
    {
      path: "/link",
      name: "CreateLink",
      component: () => import("@/views/CreateLink.vue"),
      meta: { requireLogin: true, requireApprove: true },
    },
    {
      path: "/stat",
      name: "StatisticsView",
      component: () => import("@/views/StatisticsView.vue"),
      meta: { requireLogin: true, requireApprove: true },
    },
    {
      path: "/cal",
      name: "CalculationView",
      component: () => import("@/views/CalculationView.vue"),
      meta: { requireLogin: true, requireApprove: true },
    },
    {
      path: "/notice",
      name: "NoticeView",
      component: () => import("@/views/NoticeView.vue"),
      meta: { requireLogin: true, requireApprove: true },
    },
    {
      path: "/notice/:id",
      name: "NoticeDetailView",
      component: () => import("@/views/NoticeDetailView.vue"),
      meta: { requireLogin: true, requireApprove: true },
    },
    {
      path: "/faq",
      name: "FaqView",
      component: () => import("@/views/FaqView.vue"),
      meta: { requireLogin: true, requireApprove: true },
    },

    //파트너정보 변경
    {
      path: "/account/payment-info",
      name: "PaymentInfoView",
      component: () => import("@/views/account/PaymentInfoView.vue"),
      meta: { requireLogin: true },
    },

    //알림
    {
      path: "/account/alert",
      name: "AlertView",
      component: () => import("@/views/AlertView.vue"),
      meta: { requireLogin: true },
    },

    //회원탈퇴
    {
      path: "/account/delete",
      name: "DeleteAccountView",
      component: () => import("@/views/account/DeleteAccountView.vue"),
      meta: { requireLogin: true },
    },

    //404
    {
      path: "/:notFound(.*)",
      redirect: "/",
      meta: { requireLogin: true },
    },
  ],
});

router.beforeEach(async (to, from, next) => {
  if (to.meta.requireLogin && !store.getters["auth/authToken"]) {
    //로그인이 필요한 페이지로 갈 때 토큰이 없는 경우
    next({ name: "LoginView" });
  } else {
    // next();

    if (
      to.meta.requireApprove &&
      store.getters["auth/authUser"]["all_approved_status"] !== "승인완료"
    ) {
      //승인이 필요한 페이지로 갈 때 대기/반려 사용자인 경우
      next({ name: "PaymentInfoView" });
    } else {
      next();
    }
  }
});

export default router;
