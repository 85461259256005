<template>
  <div>
    <!-- 로그인/회원가입 -->
    <!-- <div v-if="!isMember"> -->
    <div v-if="!authUser">
      <router-view :key="$route.fullPath" />
    </div>

    <!-- 본문 -->
    <div v-else>
      <v-app>
        <v-app-bar app clipped-left color="#fff" class="the_header">
          <v-app-bar-nav-icon
            color="primary"
            @click="drawer = !drawer"
          ></v-app-bar-nav-icon>
          <div class="header_logo_wrap">
            <v-img src="@/assets/tingsearch_partners_logo.svg" contain />
          </div>

          <v-spacer></v-spacer>

          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on" color="primary">
                <v-icon v-if="!authUser.unchecked_alert_count"
                  >mdi-account-circle</v-icon
                >
                <v-badge
                  v-else
                  dot
                  bordered
                  color="red"
                  offset-x="20"
                  offset-y="20"
                >
                  <v-avatar>
                    <v-icon>mdi-account-circle</v-icon>
                  </v-avatar>
                </v-badge>
              </v-btn>
            </template>

            <v-list v-if="authUser">
              <!-- 파트너정보 변경 -->
              <v-list-item>
                <v-list-item-avatar
                  color="primary"
                  size="36"
                  class="justify-center"
                  @click="moveToPaymentInfo"
                >
                  <v-btn icon dark>
                    <v-icon>mdi-pencil-outline</v-icon>
                  </v-btn>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title class="title">
                    {{ authUser.name }}님
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ authUser.email }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-divider class="my-2" />

              <!-- 알림 -->
              <v-list-item @click="moveToAlert" dense>
                <v-list-item-icon>
                  <v-icon v-if="!authUser.unchecked_alert_count"
                    >mdi-bell-outline</v-icon
                  >
                  <v-badge
                    v-else
                    dot
                    bordered
                    color="red"
                    offset-x="10"
                    offset-y="10"
                  >
                    <v-icon>mdi-bell-outline</v-icon>
                  </v-badge>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>알림</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <!-- 파트너정보 변경 -->
              <!-- <v-list-item @click="moveToPaymentInfo" dense>
                <v-list-item-icon>
                  <v-icon>mdi-pencil-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>파트너정보 변경</v-list-item-title>
                </v-list-item-content>
              </v-list-item> -->

              <!-- 로그아웃 -->
              <v-list-item @click="logout" dense>
                <v-list-item-icon>
                  <v-icon>mdi-logout</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>로그아웃</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <!-- 파트너 탈퇴 -->
              <v-list-item @click="moveToDeleteAccount" dense>
                <v-list-item-icon>
                  <v-icon>mdi-account-remove-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>파트너 탈퇴</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-app-bar>

        <v-navigation-drawer v-model="drawer" app clipped floating color="#fff">
          <div class="mt-5">
            <v-list rounded :disabled="isMenuDisable">
              <v-list-item
                v-for="item in menuList"
                :key="item.text"
                :to="item.link"
                color="primary"
              >
                <v-list-item-icon>
                  <v-icon v-text="item.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title v-text="item.text"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </div>

          <template v-slot:append>
            <div class="px-3 d-flex flex-column">
              <v-btn @click="moveToTingsearch" class="footer_btn" depressed>
                팅서치 바로가기
              </v-btn>
              <p class="footer_text">©Tingsearch. All Rights Reserved.</p>
            </div>
          </template>
        </v-navigation-drawer>

        <v-main style="background-color: #f7f8fb">
          <router-view :key="$route.fullPath" />
        </v-main>
      </v-app>
    </div>
  </div>
</template>

<script>
import { MENU } from "@/plugins/menu";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "App",

  data: () => ({
    isMember: false,

    drawer: true,
    menuList: MENU,
  }),

  computed: {
    user() {
      return this.$store.state.auth.user;
    },

    isMenuDisable() {
      if (this.authUser) {
        if (this.authUser.all_approved_status !== "승인완료") {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },

    ...mapGetters({
      authUser: "auth/authUser",
    }),
  },

  watch: {
    $route(v) {
      if (
        v.name === "LoginView" ||
        v.name === "RegisterView" ||
        v.name === "PasswordView"
      ) {
        this.isMember = false;
      } else {
        this.isMember = true;
      }
    },
  },

  mounted() {
    if (
      this.$route.name === "LoginView" ||
      this.$route.name === "RegisterView" ||
      this.$route.name === "PasswordView"
    ) {
      this.isMember = false;
    } else {
      this.isMember = true;
    }
  },

  methods: {
    ...mapActions({
      logoutAction: "auth/logout",
    }),
    logout() {
      this.logoutAction();
      this.$router.push({ name: "LoginView" });
    },
    moveToPaymentInfo() {
      this.$router.push({ name: "PaymentInfoView" }).catch(() => {});
    },
    moveToAlert() {
      this.$router.push({ name: "AlertView" }).catch(() => {});
    },
    moveToDeleteAccount() {
      this.$router.push({ name: "DeleteAccountView" }).catch(() => {});
    },
    moveToTingsearch() {
      window.open("https://tingsearch.com/");
    },
  },
};
</script>

<style>
.the_header {
  box-shadow: 0px 4px 20px rgba(0, 10, 103, 0.03) !important;
  z-index: 99;
}
.header_logo_wrap {
  width: 199px;
  height: 28px;
}
.the_nav {
  box-shadow: 0 20px 20px 12px rgb(0 65 162 / 6%);
  border-end-end-radius: 100px;
}

.white_card_box {
  padding: 24px;
  margin-bottom: 20px;
  box-shadow: 0px 0px 40px rgba(0, 13, 135, 0.02) !important;
  border-radius: 20px !important;
}
.white_card_box > .v-card__text {
  padding: 0;
}
.white_card_caption {
  margin: 0 !important;
  width: 100px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #67748e;
  white-space: nowrap;
}
.white_card_title h3 {
  margin-bottom: 16px;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  color: #495057;
}
.white_card_list {
  list-style: inside;
}

.footer_btn {
  height: 56px !important;
  background: #3943ff !important;
  border-radius: 12px;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: #ffffff !important;
  margin-bottom: 24px;
}
.footer_text {
  text-align: center;
  font-size: 12px;
  line-height: 18px;
  color: #9ba3b3;
  margin-bottom: 40px !important;
}

@media all and (max-width: 576px) {
  .header_logo_wrap {
    width: 130px;
    height: auto;
  }
}
</style>
