import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "@/router";
import store from "@/store";
import VueCookies from "vue-cookies";

Vue.use(VueCookies); //쿠키 사용
Vue.$cookies.config("7d"); //쿠키 만료일 (글로벌 세팅)

Vue.config.productionTip = false;

store
  .dispatch("auth/attempt", { token: localStorage.getItem("tsp_token") })
  .then(() => {
    new Vue({
      vuetify,
      router,
      store,
      render: (h) => h(App),
    }).$mount("#app");
  });

/** 
store.dispatch("auth/attempt", { token: localStorage.getItem("tsp_token") });
new Vue({
  vuetify,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
*/
