import { fetchUser } from "@/api/index";

export default {
  namespaced: true,

  state: {
    token: null,
    user: null,
  },

  getters: {
    authToken(state) {
      return state.token;
    },
    authUser(state) {
      return state.user;
    },
  },

  mutations: {
    setToken(state, payload) {
      state.token = payload;
    },
    setUser(state, payload) {
      state.user = payload;
    },
  },

  actions: {
    async attempt({ commit, dispatch }, payload) {
      if (payload.token) {
        //토큰 있을 때
        try {
          commit("setToken", payload.token);
          let response = await fetchUser();
          commit("setUser", response.data.user);
        } catch (err) {
          //토큰 있지만 맞지 않을 때
          dispatch("logout");
        }
      } else {
        //토큰 없을 때
        dispatch("logout");
      }
    },

    logout({ commit }) {
      commit("setToken", null);
      commit("setUser", null);
      localStorage.removeItem("tsp_token");
    },
  },
};
