import axios from "axios";
import { setInterceptors } from "./common/interceptors";

import store from "@/store";

function createInstance() {
  const instance = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
  });
  return setInterceptors(instance);
}
const instance = createInstance();

//로그인
export function loginUser(userData) {
  return instance.post("login", userData);
}
//새로고침 시 유저 정보
export function fetchUser() {
  return instance.get("me");
}

/**
 * 회원가입
 */
//이메일 확인
export function checkUserEmail(email) {
  return instance.post("validateEmail", email);
}
//유저 등록
export function registerUser(userData) {
  return instance.post("register", userData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}
//새로고침 시 유저 정보
export function fetchBankList() {
  return instance.get("banks");
}

/**
 * 비밀번호 찾기
 */
//비밀번호 찾기
export function resetUserPassword(email) {
  return instance.post("reset-password", email);
}

/**
 * 파트너정보 변경
 */
//파트너정보 조회
export function fetchUserDetail(userId) {
  return instance.get(`user/${userId}`);
}
//비밀번호 변경
export function changeUserPassword(pwData) {
  return instance.patch("updatePassword", pwData);
}
//파트너정보 수정
export function editUser(userData) {
  return instance.post("userUpdate", userData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

/**
 * 알림
 */
//알림목록 조회
export function fetchUserAlerts(page) {
  return instance.get(`alerts?page=${page}`);
}

/**
 * 파트너 탈퇴
 */
//파트너 탈퇴 이유 목록
export function fetchWithdrawalReason() {
  return instance.get("withdrawalReasons");
}
//파트너 탈퇴
export function deleteUserAccount(params) {
  return instance.delete("user", params);
}

/**
 * 링크생성
 */
//채널 목록
export function fetchChannel() {
  return instance.get("channels");
}
//채널 생성
export function createChannel(channelData) {
  return instance.post("channels", channelData);
}
//채널 삭제
export function removeChannel(channelId) {
  return instance.delete(`channels/${channelId}`);
}
//링크 생성
export function createLink(params) {
  return instance.get("createLink", params);
}

/**
 * 통계
 */
//통계 기간별 조회
export function fetchStatistics(params) {
  return instance.get("/statistics", params);
}
//통계 엑셀 다운로드
export function exportStatExcel(params) {
  return axios({
    baseURL: process.env.VUE_APP_API_URL + "/export",
    headers: {
      Authorization: `Bearer ${store.state.auth.token}`,
    },
    methods: "GET",
    responseType: "blob",
    params,
  });
}

/**
 * 정산
 */
//월별 정산기록 목록
export function fetchSetCalculation() {
  return instance.get("mthCalcLog");
}
//세금계산서 요청
export function applyTaxBill(id) {
  return instance.patch(`mthCalcLog/${id}/taxBillStatus`, { taxBillStatus: 2 });
}

/**
 * 공지사항
 */
//공지사항 목록
export function fetchNotice(page) {
  return instance.get(`notices?page=${page}`);
}
//공지사항 조회
export function fetchNoticeDetail(noticeId) {
  return instance.get(`notices/${noticeId}`);
}

/**
 * FAQ
 */
//FAQ 목록
export function fetchFaq(params) {
  return instance.get("faqs", params);
}
