export const MENU = [
  {
    icon: "mdi-link",
    text: "링크생성",
    link: "/link",
  },
  {
    icon: "mdi-chart-bar",
    text: "통계",
    link: "/stat",
  },
  {
    icon: "mdi-file-document-check-outline",
    text: "정산",
    link: "/cal",
  },
  {
    icon: "mdi-comment-alert-outline",
    text: "공지사항",
    link: "/notice",
  },
  {
    icon: "mdi-frequently-asked-questions",
    text: "FAQ",
    link: "/faq",
  },
];
